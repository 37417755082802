import React from 'react'
import { useSelector } from 'react-redux'

const HaveAccess = ({ children, roles = [] }) => {
    const user = useSelector((state) => state.app.user)
    const haveAccess = roles.includes(user.role)
    if (haveAccess) {
        return children
    }
}

export default HaveAccess