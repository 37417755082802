import $host from './index'
import { clientsAC, isSpinAC } from '../store/reducers/app';
import notification from '../utils/openNotification';

const getAllClientsApi = () => async (dispatch) => {
  try {
    dispatch(isSpinAC(true))
    const { data } = await $host.get('clients');
    console.log('cliets', data);
    dispatch(clientsAC(data.response))
  } catch (e) {
    notification('error', e.message)
  }
  finally {
    dispatch(isSpinAC(false))
  }
}

const createNewClientApi = (query) => async (dispatch) => {
  let token=localStorage.getItem('at')
  try {
    dispatch(isSpinAC(true))
    let res=await fetch('http://api.teztar.ru/clients', {
      method:'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${token}`
      },
      body: JSON.stringify(query)
    })
    console.log(query)
    console.log(res);
    dispatch(getAllClientsApi())
  } catch (e) {
    notification('error', e.message)
  }
  finally {
    dispatch(isSpinAC(false))
  }
}

export { getAllClientsApi, createNewClientApi }
