import $host from './index'
import { isSpinAC } from '../store/reducers/app';
import notification from '../utils/openNotification';
import { ticketsAC, ticketsPageAC, completedTicketsAC, currentTicketAC } from '../store/reducers/cabinet';

const getTicketsApi = () => async (dispatch) => {
  try {
    dispatch(isSpinAC(true))
    const { data } = await $host.get('queues')
    console.log('queue', data);
    dispatch(completedTicketsAC(data.response.completedTickets))
    dispatch(ticketsAC(data.response))
    dispatch(currentTicketAC(data.response.currentTicket))
  } catch (e) {
    notification('error', e.meta.message)
  }
  finally {
    dispatch(isSpinAC(false))
  }
}

const getTicketsPage = (page) => async (dispatch) => {
  try {
    dispatch(isSpinAC(true))
    const { data } = await $host.get(`queues?page=${page}`)
    dispatch(ticketsPageAC(data.response))
    console.log("tickets page", data.response);

  } catch (e) {
    notification('error', e.meta.message)
  }
  finally {
    dispatch(isSpinAC(false))
  }
}


const inviteTicketApi = (id) => async (dispatch) => {
  try {
    dispatch(isSpinAC(true))
    await $host.post(`queues/${id}/invite`)
    dispatch(getTicketsApi())
  } catch (e) {
    // notification('error', e.meta.message)
  }
  finally {
    dispatch(isSpinAC(false))
  }
}

const acceptTicketApi = (id, query) => async (dispatch) => {
  try {
    dispatch(isSpinAC(true))
    await $host.post(`queues/${id}/accept`)
    dispatch(getTicketsApi())
  } catch (e) {
    notification('error', e.meta.message)
  }
  finally {
    dispatch(isSpinAC(false))
  }
}

const skipTicketApi = (id, query) => async (dispatch) => {
  try {
    dispatch(isSpinAC(true))
    await $host.post(`queues/${id}/skip`)
    dispatch(getTicketsApi())
  } catch (e) {
    notification('error', e.meta.message)
  }
  finally {
    dispatch(isSpinAC(false))
  }
}

const closeTicketApi = (id, query) => async (dispatch) => {
  try {
    dispatch(isSpinAC(true))
    await $host.post(`queues/${id}/close`)
    dispatch(getTicketsApi())
  } catch (e) {
    notification('error', e.meta.message)
  }
  finally {
    dispatch(isSpinAC(false))
  }
}











const doneTicketApi = () => async (dispatch) => {
  try {
    dispatch(isSpinAC(true))
    await $host.post('cabinet/done')
    dispatch(getTicketsApi())
  } catch (e) {
    notification('error', e.meta.message)
  }
  finally {
    dispatch(isSpinAC(false))
  }
}

export {
  getTicketsApi,
  getTicketsPage,
  acceptTicketApi,
  doneTicketApi,
  inviteTicketApi,
  skipTicketApi,
  closeTicketApi
}
