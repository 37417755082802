import React from "react";
import styled from "styled-components";
import { Layout, Dropdown, Menu, Avatar } from "antd";
import {
  Navigate,
  NavLink,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { UserOutlined, FileProtectOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { isAuthAC } from "../../store/reducers/app";

import Me from "./Me";
import MyClients from "./MyClients";
import Cabinet from "./Cabinet";
import AllUsers from "./AllUsers";
import ServiceCenters from "./ServiceCenters";
import Services from "./Services";
import Clients from "./Clients";
import Queue from "./Queue";

import { logOutApi } from "../../http/auth";
import ServiceCategories from "./ServiceCategories";
import ProtectedRoute from "../Auth/ProtectedRoute";
import HaveAccess from "../Auth/HaveAccess";

const { Header, Sider, Content } = Layout;

const DashboardStyled = styled.div`
  .trigger {
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: #e53540 !important;
  }
  .ant-menu-item,
  .ant-menu-submenu span,
  i {
    color: #fff !important;
  }
  .ant-menu-item,
  .ant-menu-submenu {
    border-radius: 0;
  }

  > section {
    min-height: 100vh;

    > section {
      > header {
        padding: 0 24px !important;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        background: #fff !important;
        display: flex;
        align-items: center;
        justify-content: end;
      }

      > main {
        margin: 16px;
        border-radius: 4px;
        padding: 24px;
        min-height: 280px;
        background: #fff !important;
      }
    }

    > aside {
      background: #001529 !important;
      width: 300px;

      > div {
        > div {
          margin: 20px 20px 30px 20px;

          > img {
            max-width: 170px;
            width: 100%;
          }
        }
      }
    }
  }
`;

function Dashboard() {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.app.user);

  const nav = [
    {
      key: "/",
      label: "Главная",
      component: <Me />,
      icon: <FileProtectOutlined />,
      roles: ["worker", "super_admin","admin"]
    },
    {
      key: "/queue",
      label: "Очередь",
      component: <ProtectedRoute roles={["worker", "admin", "super_admin"]}> <Queue /></ProtectedRoute>,
      icon: <FileProtectOutlined />,
      roles: ["worker", "super_admin", "admin"]
    },
    {
      key: "/cabinet",
      label: "Кабинет",
      component: <ProtectedRoute roles={["worker", "admin", "super_admin"]}> <Cabinet /></ProtectedRoute>,
      icon: <FileProtectOutlined />,
      roles: ["worker", "super_admin", "admin"]
    },
    {
      key: "/myClients",
      label: "Мои клиенты",
      component: <ProtectedRoute roles={["worker", "admin", "super_admin"]}> <MyClients /></ProtectedRoute>,
      icon: <FileProtectOutlined />,
      roles: ["worker", "super_admin", "admin"]
    },
    {
      key: "/services",
      label: "Услуги",
      component: <ProtectedRoute roles={["admin", "super_admin"]}><Services /></ProtectedRoute>,
      icon: <FileProtectOutlined />,
      roles: ["super_admin", "admin"]
    },
    {
      key: "/categories",
      label: "Категории",
      component: <ProtectedRoute roles={["admin", "super_admin"]}><ServiceCategories /></ProtectedRoute>,
      icon: <FileProtectOutlined />,
      roles: ["super_admin", "admin"]
    },
    {
      key: "/users",
      label: "Пользователи",
      component: <ProtectedRoute roles={["admin", "super_admin"]}><AllUsers /></ProtectedRoute>,
      icon: <FileProtectOutlined />,
      roles: ["super_admin", "admin"]
    },
    {
      key: "/clients",
      label: "Клиенты",
      component: <ProtectedRoute roles={["admin", "super_admin"]}><Clients /></ProtectedRoute>,
      icon: <FileProtectOutlined />,
      roles: ["super_admin", "admin"]
    },
    {
      key: "/serviceCenters",
      label: "Филиалы",
      component: <ProtectedRoute roles={["admin", "super_admin"]}><ServiceCenters /></ProtectedRoute>,
      icon: <FileProtectOutlined />,
      roles: ["super_admin"]
    },
  ];

  const menu = (
    <Menu
      items={[
        {
          key: "4",
          danger: true,
          label: "Выйти",
          onClick: () => {
            dispatch(logOutApi());
            localStorage.removeItem("at");
            dispatch(isAuthAC(false));
          },
        },
      ]}
    />
  );

  return (
    <DashboardStyled>
      <Layout>
        <Sider
          width={250}
          trigger={null}
          collapsible
          className="h-[100vh]"
          style={{
            position: "fixed",
          }}
        >
          <div className="logo bg-blue- h-[10vh] flex items-center">
            <h1
              style={{ color: "#49bff4" }}
              className="text-3xl font-bold ml-[25px]"
            >
              TezTar
            </h1>
          </div>

          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["/main"]}
            selectedKeys={[location.pathname]}
          >
            {nav.map((el) => {
              if (!el?.component) {
                return (

                  <Menu.SubMenu key={el.key} title={el.label} icon={el.icon}>
                    {el?.children.map((elCh) => (
                      <Menu.Item key={`${el.key}${elCh.key}`}>
                        <NavLink to={`${el.key}${elCh.key}`}>
                          <span>{elCh.label}</span>
                        </NavLink>
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>

                );
              }
              return (
                <HaveAccess roles={el.roles}>
                  <Menu.Item key={el.key}
                    style={{
                      width: '90%',
                      marginLeft: '5%'
                    }}>
                    <NavLink to={el.key}>
                      {el.icon}
                      <span className="font-[Roboto]">{el.label}</span>
                    </NavLink>
                  </Menu.Item>
                </HaveAccess>
              );
            })}
          </Menu>
        </Sider>

        <Layout className="ml-[250px]">
          <Header className="bg-white sticky top-0 z-10 border-b-[3px] border-solid px-[30px] border-[#AFAFAF3D]">
            <div className="flex items-center justify-between text-[#000]">
              <p className="mr-4 font-[Roboto]">
                Добро пожаловать {user?.first_name} !
              </p>
              <Dropdown overlay={menu} trigger={["click"]}>
                <Avatar icon={<UserOutlined />} style={{ cursor: "pointer" }} />
              </Dropdown>
            </div>
          </Header>

          <Content className="min-h-[83vh] m-[30px]">
            <Routes>
              {nav.map((rout) =>
                rout?.component ? (
                  <Route
                    key={rout.key}
                    path={`${rout.key}/*`}
                    element={rout.component}
                  />
                ) : (
                  rout.children.map((roCh) => (
                    <Route
                      key={`${rout.key}${roCh.key}`}
                      path={`${rout.key}${roCh.key}/*`}
                      element={roCh.component}
                    />
                  ))
                )
              )}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </DashboardStyled>
  );
}

export default Dashboard;
