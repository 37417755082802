import React, { useEffect, useState } from "react";
import { Divider, Button, Modal, Input, Pagination, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";

import {
  acceptTicketApi,
  doneTicketApi,
  getTicketsApi,
  inviteTicketApi,
  getTicketsPage,
  skipTicketApi,
  closeTicketApi,
} from "../../http/cabinet";
import { setTiketsPage } from "../../store/reducers/cabinet";
import "./MyClients.css";

function MyClients() {
  const dispatch = useDispatch();
  const tickets = useSelector((state) => state.cabinet.tickets);
  const ticketsPage = useSelector((state) => state.cabinet.ticketsPage);
  const tiketsPagination = useSelector(
    (state) => state.cabinet.tiketsPagination
  );
  console.log("Page", ticketsPage);

  console.log("tick", tickets);

  const currentTicket = useSelector((state) => state.cabinet.currentTicket);
  console.log(tickets);

  const inviteTicket = () => {
    dispatch(inviteTicketApi());
  };

  const acceptTicket = () => {
    dispatch(acceptTicketApi());
  };

  const doneTicket = () => {
    dispatch(doneTicketApi());
  };

  const [access, setAccess] = useState([]);

  const [accessText, setAccessText] = useState('Принять')

  console.log('accessss', access);

  const getId = (id, item) => {
    dispatch(inviteTicketApi(id));
    // acceptClient(id)
    setModal(true);
    access.splice(0, 1);
    access.push(item);
    console.log("access", access);
  };

  const acceptClient = () => {
    access.map((el) => {
      dispatch(acceptTicketApi(el.id));
    });
    setAccessText('Завершить')
  };

  const closeClient = () => {
    access.map((el) => {
      dispatch(closeTicketApi(el.id));
    });
    setModal(false);
    setAccessText('Принять')

  }

  const skipClient = () => {
    access.map((el) => {
      dispatch(skipTicketApi(el.id));
    });
    setModal(false);
  };

  const [modal, setModal] = useState(false);
  const showModal = () => {
    setModal(true);
  };
  const handleOk = () => {
    setModal(false);
  };
  const handleCancel = () => {
    setModal(false);
  };

  useEffect(() => {
    dispatch(getTicketsApi());
    dispatch(getTicketsPage(tiketsPagination));
  }, []);

  return (
    <div>
      <h1 className="text-lg">Мои клиенты</h1>
      <Divider />
      <div className="flex justify-between">
        <div className="w-[100%]">
          <div className="grid grid-cols-6 border-b pb-2 gap-3">
            <p className="font-semibold text-center">Очередь</p>
            <p className="font-semibold text-center">Тип клиента</p>
            <p className="font-semibold text-center">Продукт</p>
            <p className="font-semibold text-center">Статус</p>
            <p className="font-semibold text-center"> Пригласить </p>
            <p className="font-semibold text-center">Контакты</p>
          </div>
          <div>
            {ticketsPage.items.map((elem, intex) => {
              console.log("aaaaaa", elem);
              return (
                <div
                  key={elem.id}
                  className="grid grid-cols-6 mt-5 text-xs gap-3 items-center text-[13px]"
                >
                  <p className="text-center" >{elem.number}</p>
                  <p className="text-center" >-</p>
                  <p className="text-center" >{elem.service?.name}</p>
                  <p className="text-center" >{elem.status?.label}</p>
                  {/* <button onClick={() => {
                  getId(elem.id,item)
                  console.log("MANSUR", elem.id);
                }} className="bg-green-500 text-white p-[10px] rounded-[5px]">Пригласить</button> */}
                  {elem.status.value == 1 ? <Button
                    type="link"
                    onClick={() => {
                      showModal();
                      getId(elem.id, elem);
                    }}
                  >
                    Пригласить
                  </Button> : elem.status.value == 2 ?
                    <p className="w-[80%] p-[10px_15px] text-center bg-yellow-500 m-[auto] rounded-[4px] text-[13px] font-[600] " >Приглашено</p> : elem.status.value == 3 ?
                      <p className="w-[80%] p-[10px_15px] text-center bg-orange-500 m-[auto] rounded-[4px] text-[13px] font-[600]"  >Обслуживается</p> :
                      <p className="w-[80%] p-[10px_15px] text-center bg-green-500 m-[auto] rounded-[4px] text-[13px] font-[600] text-white" >Клиент обслужен</p>
                  }
                  <p className="text-center">{elem.branch.phone}</p>
                </div>
              )
            })}

            {/* {ticketsPage.map((item) => (
              item.status.value == 1 ?
                <div key={item.id} className="grid grid-cols-6 mt-5 text-xs gap-3 items-center text-[13px]">
                  <p>{item.number}</p>
                  <p>-</p>
                  <p>{item.service.name}</p>
                  <p>{item.status.label}</p>
                  <p> */}
            {/* <button onClick={() => {
                  getId(item.id,item)
                  console.log("MANSUR", item.id);
                }} className="bg-green-500 text-white p-[10px] rounded-[5px]">Пригласить</button> */}
            {/* <Button type="link" onClick={showModal}>
                      Пригласить
                    </Button>

                  </p>
                  <p>{item.branch.phone}</p>
                </div>
                : null
            ))} */}
          </div>
          <Divider />
          <Pagination
            total={ticketsPage.pagination.total}
            pageSize={10}
            onChange={(page) => dispatch(getTicketsPage(page))}
            footer={null}
          />
          {/* <Table 
          columns={columns}
          footer={null}
          dataSource={ticketsPage} 
          pagination={{
            total: 50,
            pageSize: 10,
            onChange: (page) => {
              dispatch(getTicketsPage(page));
            },
          }}
          /> */}
          {/* <Button onClick={() => { setTiketsPage(1) }}> 1 </Button>
          <Button onClick={() => { setTiketsPage(2) }}> 2 </Button>
          <Button onClick={() => { setTiketsPage(3) }}> 3 </Button> */}
          {/* <div className="mt-5 flex justify-end">
            {tickets?.length > 0 &&
              currentTicket?.length < 1 &&
              tickets?.status.name !== "invited" ? (
              <Button
                style={{ backgroundColor: "#59b039", color: "#fff" }}
                onClick={inviteTicket}
              >
                Пригласить
              </Button>
            ) : null}
          </div>
          <div className="mt-5 flex justify-end">
            {tickets?.status?.label === "invited" ? (
              <Button 
                disabled
                style={{ backgroundColor: "#59b039", color: "#fff" }}
                onClick={inviteTicket}
              >
                Приглашен
              </Button>
            ) : null}
            <div className="ml-3">
              {tickets?.status?.name === "invited" ? (
                <Button
                  style={{ backgroundColor: "#59b039", color: "#fff" }}
                  onClick={acceptTicket}
                >
                  Принять
                </Button>
              ) : null}
            </div>
          </div> */}
        </div>
        {/* <div className="w-72 bg-slate-100 rounded-lg h-64">
          <p className="text-center text-3xl py-4">
            {currentTicket?.number}
          </p>
          {currentTicket?.length > 0 ? (
            <div className="mt-5 px-5 pb-5">
              <div className="flex justify-between pb-1">
                <p className="font-bold">Дата и время</p>
                <p>{currentTicket[0]?.created_at}</p>
              </div>
              <div className="flex justify-between pb-1">
                <p className="font-bold">Тип клиента</p>
                <p>-</p>
              </div>
              <div className="flex justify-between pb-1">
                <p className="font-bold">Тип продукта</p>
                <p>-</p>
              </div>
              <div className="flex justify-between pb-1">
                <p className="font-bold">Номер телефона</p>
                <p>{currentTicket?.client.phone}</p>
              </div>
            </div>
          ) : null}

          <div className="px-5 flex justify-center mb-5">
            {currentTicket?.length > 0 ? (
              <Button type="primary" onClick={doneTicket}>
                Завершить
              </Button>
            ) : null}
          </div>
        </div> */}
      </div>

      <Modal
        footer={[
          accessText == 'Принять' ?
            <Button danger onClick={skipClient}>Пропустить</Button>
            : null,
          <Button
            className="bg-green-600 text-white btn-access"
            onClick={() => {
              accessText == "Принять" ? acceptClient() : closeClient()
            }}
          >
            {accessText}
          </Button>,
        ]}
        open={modal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {access?.map((e, i) => {
          return (
            <div>
              <h1 className="text-center" style={{ fontSize: '40px', fontWeight: 'bolder' }}>{e.number}</h1>
              <div><b>Дата и время:</b> <span>{e.created_at}</span> </div>
              <div><b>Тип продукта:</b><span>{e.service?.name}</span> </div>
              <div><b>Филиал:</b> <span>{e.branch?.address}</span> </div>
            </div>
          );
        })}
      </Modal>
    </div>
  );
}

export default MyClients;
