import { useSelector } from 'react-redux'
import { Navigate, useNavigate } from "react-router-dom";
const ProtectedRoute = ({ children, roles = [] }) => {
    
    const user = useSelector((state) => state.app.user)
    const haveAccess = roles.includes(user.role)
    return haveAccess ? children : <Navigate to={'/'} replace />;

}

export default ProtectedRoute