import { createSlice } from '@reduxjs/toolkit';

const cabinet = createSlice({
  name: 'cabinet',
  initialState: {
    tickets: [],
    ticketsPage:[],
    completedTickets: [],
    currentTicket: [],
    tiketsPagination: 1,
  },
  reducers: {
    completedTicketsAC(state, action) {
      state.completedTickets = action.payload
    },
    ticketsAC(state, action) {
      state.tickets = action.payload;
    },
    ticketsPageAC(state, action) {
      state.ticketsPage = action.payload;
    },
    currentTicketAC(state, action) {
      state.currentTicket = action.payload
    },
    setTiketsPage(state, action) {
      state.tiketsPagination = action.payload
    }
  },
});

export const { setTiketsPage,ticketsAC,ticketsPageAC, completedTicketsAC, currentTicketAC } = cabinet.actions;
export default cabinet.reducer;
