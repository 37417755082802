import React, { useEffect, useState } from 'react';
import { Divider, Table, Button, Modal, Input, Select, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsersApi, createNewUserApi } from '../../http/users';
import { getServiceCentersListApi } from '../../http/serviceCenters';
import { getServicesUser } from '../../http/services';

const columns = [
  {
    title: ' ФИО',
    dataIndex: 'full_name',
    key: 'full_name',
  },
  {
    title: 'Филиал',
    dataIndex: 'service_center_id',
    key: 'service_center_id',
  },
  {
    title: 'Почта',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Статус',
    dataIndex: 'is_block',
    key: 'is_block',
    render: item => <Tag color={item === 1 ? 'green' : 'volcano'}>{item === 1 ? 'активный' : 'заблокирован'}</Tag>,
  },
  {
    title: 'Дата регистрации',
    dataIndex: 'created_at',
    key: 'created_at',
  },
];

function AllUsers() {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const users = useSelector(state => state.app.users);
  const serviceCenters = useSelector(state => state.app.serviceCenters);
  const services = useSelector(state => state.app.servicesUser);

  console.log('user-ser', services);

  console.log("ServiceCenterS", serviceCenters);


  const [newUser, setNewUser] = useState({
    first_name: '',
    last_name: '',
    middle_name: '',
    email: '',
    password: '',
    password_confirmation: "",
    branch_id: '',
    role_id: '',
    services: []
  });

  console.log("NEW_USER", newUser);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    dispatch(createNewUserApi(newUser))
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleChange = value => {
    console.log(value);
    setNewUser({ ...newUser, services: value });
  };
  const serviceChange = value => {
    console.log("serviceChange", value);
    setNewUser({ ...newUser, branch_id: value });
  };
  const roleChange = value => {
    console.log("RoleChange", value);
    setNewUser({ ...newUser, role_id: value });
  };




  useEffect(() => {
    dispatch(getAllUsersApi());
    dispatch(getServiceCentersListApi());
    dispatch(getServicesUser());
  }, []);

  return (
    <div>
      <div className='flex justify-between'>
        <h1 className='text-lg'>Все сотрудники</h1>
        <Button type='primary' onClick={() => showModal()}>
          Добавить сотрудника
        </Button>
      </div>
      <Divider />
      <Table columns={columns} dataSource={users} />
      <Modal
        style={{
          top: 20,
        }}
        title='Добавить сотрудника'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className='mt-5'>
          <Input
            placeholder='Имя'
            onChange={e => {
              setNewUser({ ...newUser, first_name: e.target.value });
            }}
          />
        </div>
        <div className='mt-5'>
          <Input
            placeholder='Фамилия'
            onChange={e => {
              setNewUser({ ...newUser, last_name: e.target.value });
            }}
          />
        </div>
        <div className='mt-5'>
          <Input
            placeholder='Отчество'
            onChange={e => {
              setNewUser({ ...newUser, middle_name: e.target.value });
            }}
          />
        </div>
        <div className='mt-5'>
          <Input
            placeholder='Email'
            onChange={e => {
              setNewUser({ ...newUser, email: e.target.value });
            }}
          />
        </div>

        <div className='mt-5'>
          <Input placeholder='Пароль'
            onChange={e => {
              setNewUser({ ...newUser, password: e.target.value });
              console.log(typeof e.target.value);
            }}
          />
        </div>
        <div className='mt-5'>
          <Input placeholder='Подтвердите пароль'
            onChange={e => {
              setNewUser({ ...newUser, password_confirmation: e.target.value });
            }}
          />
        </div>
        <div className='mt-3'>
          <p className='text-xs mb-1'>Выбор филиала</p>
          <Select placeholder='Выберите' className='w-full' options={serviceCenters} onChange={serviceChange} />
        </div>
        <div className='mt-3'>
          <p className='text-xs mb-1'>Доступы</p>
          <Select
            mode='multiple'
            placeholder='Выберите'
            onChange={handleChange}
            style={{ width: '100%' }}
            options={
              [{
                value: 1,
                label: 'Открыть депозит',
              },
              {
                value: 2,
                label: 'Закрыть кредит',
              },]
            }
          />
        </div>
        <div className='mt-3'>
          <p className='text-xs mb-1'>Должность</p>
          <Select
            defaultValue='active'
            className='w-full'
            onChange={roleChange}
            options={[
              {
                value: '1',
                label: 'Admin',
              },
              {
                value: 'blocked',
                label: 'SuperAdmin',
              },
            ]}
          />
        </div>
        <div className='mt-3'>
          <p className='text-xs mb-1'>Статус</p>
          <Select
            defaultValue='active'
            className='w-full'
            options={[
              {
                value: 'active',
                label: 'Активен',
              },
              {
                value: 'blocked',
                label: 'заблокирован',
              },
            ]}
          />
        </div>
      </Modal>
    </div>
  );
}

export default AllUsers;
