import React, { useState, useEffect } from "react";
import { Divider, Table, Button, Modal, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryApi, getServicesApi } from "../../http/services";
import { getServiceCategoriesApi } from "../../http/serviceCategories";
import { createQueueApi } from "../../http/reception";
import { getTicketsApi, getTicketsPage } from "../../http/cabinet";

const columns = [
  {
    title: "Номер очереди",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "Статус очереди",
    dataIndex: "status",
    key: "status",
    render: (item) => <p>{item.label}</p>,
  },
  {
    title: "Создан",
    dataIndex: "created_at",
    key: "created_at",
    render: (item) => <p>{item.slice(0, 19)}</p>,
  },
];

function Queue() {
  const dispatch = useDispatch();
  const serviceCategories = useSelector((state) => state.app.serviceCategories);

  const category = useSelector((state) => state.app.category);
  console.log( "CATEGORY",category);

  const services = useSelector((state) => state.app.services);

  console.log("serv", services);

  const tickets = useSelector((state) => state.cabinet.tickets);
  console.log("tickets", tickets);

  const ticketsPage = useSelector((state) => state.cabinet.ticketsPage);
  console.log('tickpage',ticketsPage);

  // console.log('queae',services);
  console.log("service-cat", serviceCategories);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pickedCategory, setPickedCategory] = useState([]);
  const [newQueue, setNewQueue] = useState({
    phone: "",
    service_id: "",
    branch_id: 1,
  });

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const createNewQueue = () => {
    setIsModalOpen(false);
    dispatch(createQueueApi(newQueue));
  };

  const getServices = (categoryId) => {
    const tempArr = [];
    console.log("cat_id", categoryId);
    category.forEach((item) => {
      if (item.id == categoryId) {
        item.services.forEach((e, i) => {
          tempArr.push({ value: e.name, name: e.id });
        });

        console.log("temp", item);
      }
    });
    setPickedCategory(tempArr);
  };

  const serviceId = (elem) => {
    pickedCategory.map((e) => {
      if (elem === e.value) {
        setNewQueue({ ...newQueue, service_id: e.name });
      }
    });
  };

  useEffect(() => {
    dispatch(getTicketsApi());
    dispatch(getServiceCategoriesApi());
    dispatch(getServicesApi());
    dispatch(getTicketsPage())
    dispatch(getCategoryApi())
  }, []);

  return (
    <div>
      <div className="flex justify-between">
        <h1 className="text-lg">Очередь</h1>
        <Button type="primary" onClick={() => showModal()}>
          Добавить очередь
        </Button>
      </div>
      <Divider />
      <Table
        columns={columns}
        dataSource={ticketsPage.items}
        pagination={{
          total: ticketsPage?.pagination?.total  ,
          pageSize: 10,
          onChange: (page) => {
            dispatch(getTicketsPage(page));
          },
        }}
      />
      <Modal
        footer={null}
        title="Добавить очередь"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          placeholder="Номер телефона"
          onChange={(e) => {
            setNewQueue({ ...newQueue, phone: e.target.value });
          }}
        />
        <div className="mt-5">
          <Select
            style={{
              width: "100%",
            }}
            placeholder="Выберите категорию"
            onChange={(e) => {
              getServices(e);
            }}
            options={serviceCategories}
          />
        </div>
        <div className="mt-5">
          <Select
            style={{
              width: "100%",
            }}
            placeholder="Выберите услугу"
            onChange={(e) => {
              serviceId(e);
              console.log("onchange", e);
            }}
            options={pickedCategory}
          />
          {console.log("pick", pickedCategory)}
        </div>
        <div className="mt-5 flex justify-end">
          <Button type="primary" onClick={createNewQueue}>
            Добавить очередь
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default Queue;
