import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Input, Modal, Select, Space } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone, UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import {
  editPasswordUser,
  editUserApi,
  getUserApi,
} from "../../http/user";
// import ProtectedRoute from "../Auth/ProtectedRoute";

function Me() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.user);
  // console.log('user', user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [userInfo, setUserinfo] = useState([
    {
      first_name: "",
      last_name: "",
      middle_name: "",
      services: []
    },
  ]);

  const [editedUser, setEditUser] = useState({
    first_name: "",
    last_name: "",
    middle_name: ""
  });

  const showEditModal = () => {
    setEditUser({ first_name: user.first_name, last_name: user.last_name, middle_name: user.middle_name });
    setIsEditModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const editModalOk = () => {
    setIsEditModalOpen(false);
  };
  const editModalCancel = () => {
    setIsEditModalOpen(false);
  };

  const [form] = Form.useForm();

  const editUser = () => {
    console.log('EDITUSER', editedUser);
    dispatch(editUserApi(editedUser));
    setIsEditModalOpen(false);
  };

  const [passwordUser, setPasswordUser] = useState({
    password: "",
    new_password: "",
    new_password_confirmation: "",
  });
  const sevePassword = () => {
    passwordUser.password == "" || passwordUser.new_password == "" || passwordUser.new_password_confirmation == "" ? alert(`Password is no correct`) : dispatch(editPasswordUser(passwordUser));
  };


  const cleanPassword = () => {
    form.resetFields()
    setPasswordUser({
      password: "",
      new_password: "",
      new_password_confirmation: "",
    })
  };
  console.log("Password", passwordUser);

  useEffect(() => {
    dispatch(getUserApi());
  }, []);

  return (
    <div>
      <div className="flex justify-between">
        <h1 className="text-lg">Профил</h1>
      </div>
      <Divider />
      <div className="grid grid-cols-[55%_45%] gap-5">

        <div
          role="button"
          tabIndex={0}
          className="py-[15px] flex justify-center border min-h-24 rounded-[7px] shadow-[0px_0px_7px_lightgray]"
        >
          <div className="w-[93%] flex justify-between">
            <div className="w-[20%] flex justify-center items-start">
              <UserOutlined style={{ fontSize: '100px', backgroundColor: '#27688a ', color: '#fff', padding: '20px', borderRadius: '100px' }} />
            </div>
            <div className="w-[75%]">
              <h2 className="font-bold text-[30px]">{user?.last_name} &nbsp; {user?.first_name} {user?.middle_name}</h2>
              <div className="flex justify-between mt-[5px]">
                <div className="w-[40%]">
                  <h2 className="text-[20px]"> Position: </h2>
                  <h2 className="text-[20px]"> Phone number: </h2>
                  <h2 className="text-[20px]"> Email: </h2>
                </div>
                <div className="w-[60%]">
                  <h2 className="text-[20px] text-[#27688a]">{user?.role}</h2>
                  <h2 className="text-[20px] text-[#27688a]">----</h2>
                  <h2 className="text-[20px] text-[#27688a]">{user?.email}</h2>
                </div>
              </div>
              <Button className="mt-5"
                onClick={() => showEditModal()}
              >
                Изменить профиль
              </Button>
            </div>
          </div>
        </div>

        {/* <ProtectedRoute roles={["admin"]}><h1>4sdasadsd</h1></ProtectedRoute> */}
        <div
          className="py-[15px] flex justify-center border rounded-[7px] shadow-[0px_0px_7px_lightgray]"
        >
          <div className="w-[93%]">
            <Form
              form={form}
              name="basic"
              initialValues={{
                remember: true,
              }}
              // onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="current_password"
              >
                <Input.Password
                  value={passwordUser.password}
                  placeholder="Current password"
                  onChange={(e) => {
                    setPasswordUser({
                      ...passwordUser,
                      password: e.target.value,
                    })
                  }}
                />
              </Form.Item>
              <Form.Item
                name="new_password"
              >
                <Input.Password
                  value={passwordUser.new_password}
                  placeholder="New password"
                  onChange={(e) => {
                    setPasswordUser({
                      ...passwordUser,
                      new_password: e.target.value,
                    });
                  }} />
              </Form.Item>
              <Form.Item
                name="new_password_confirmation"
              >
                <Input.Password
                  value={passwordUser.new_password_confirmation}
                  placeholder="New password confirmation"
                  onChange={(e) => {
                    setPasswordUser({
                      ...passwordUser,
                      new_password_confirmation: e.target.value,
                    });
                  }} />
              </Form.Item>
              <div className="flex justify-end">
                <div className="flex w-[50%] justify-between">
                  <Button style={{ width: '45%' }}
                    onClick={cleanPassword}
                  >
                    Clean
                  </Button>
                  <Button style={{ width: '45%' }} type="primary"
                    htmlType="submit"
                    onClick={() => {
                      sevePassword();
                      cleanPassword()
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </div>

        </div>

      </div>


      <Modal
        footer={null}
        title="Редактировать пользователь"
        open={isEditModalOpen}
        onOk={editModalOk}
        onCancel={editModalCancel}
      >
        <Input
          value={editedUser.first_name}
          placeholder="Имя"
          onChange={(e) => {
            console.log(e.target.value);
            setEditUser({
              ...editedUser,
              first_name: e.target.value,
            });
          }}
        />
        <Input
          value={editedUser.last_name}
          placeholder="Фамилия"
          className="mt-5"
          onChange={(e) => {
            setEditUser({
              ...editedUser,
              last_name: e.target.value,
            });
          }}
        />
        <Input
          value={editedUser.middle_name}
          placeholder="Отчество"
          className="mt-5"
          onChange={(e) => {
            setEditUser({
              ...editedUser,
              middle_name: e.target.value,
            });
          }}
        />
        <div className="flex justify-end">
          <div className="flex w-[50%] justify-between mt-5">

            <Button style={{ width: '45%' }} onClick={editModalCancel}>
              Cancel
            </Button>
            <Button style={{ width: '45%' }} type="primary" onClick={editUser}>
              Изменить
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Me;
