import $host from './index'
import { isSpinAC, servicesAC ,servicesUserAC,setCategory} from '../store/reducers/app';
import notification from '../utils/openNotification';

const getServicesApi = () => async (dispatch) => {
  try {
    dispatch(isSpinAC(true))
    const { data } = await $host.get('services/list')
    console.log('ser cat',data);
    dispatch(servicesAC(data.response))
  } catch (e) {
    console.log('not',e);
    notification('error', e.meta.message)
  }
  finally {
    dispatch(isSpinAC(false))
  }
}
const getCategoryApi = () => async (dispatch) => {
  try {
    dispatch(isSpinAC(true))
    const { data } = await $host.get('service-categories')
    console.log('Category',data);
    dispatch(setCategory(data.response))
  } catch (e) {
    console.log('not',e);
    notification('error', e.meta.message)
  }
  finally {
    dispatch(isSpinAC(false))
  }
}


const getServicesUser = () => async (dispatch) => {
  try {
    dispatch(isSpinAC(true))
    const { data } = await $host.get('services')
    console.log('ser cat',data.response);
    dispatch(servicesUserAC(data.response.items))
  } catch (e) {
    console.log('not',e);
    notification('error', e.meta.message)
  }
  finally {
    dispatch(isSpinAC(false))
  }
}


const createServiceApi = (query) => async (dispatch) => {
  try {
    dispatch(isSpinAC(true))
    await $host.post('services', query)
    dispatch(getServicesApi())
  } catch (e) {
    notification('error', e.response.data.message)
  }
  finally {
    dispatch(isSpinAC(false))
  }
}

const updateServiceApi = (id, query) => async (dispatch) => {
  try {
    dispatch(isSpinAC(true))
    await $host.put(`services/${id}`, query)
    dispatch(getServicesApi())
  } catch (e) {
    notification('error', e.response.data.message)
  }
  finally {
    dispatch(isSpinAC(false))
  }
}

const deleteServiceApi = (id) => async (dispatch) => {
  try {
    dispatch(isSpinAC(true))
    await $host.delete(`services/${id}`)
    dispatch(getServicesApi())
  } catch (e) {
    notification('error', e.response.data.message)
  }
  finally {
    dispatch(isSpinAC(false))
  }
}


export { 
  getServicesApi,
  createServiceApi,
  updateServiceApi,
  deleteServiceApi ,
  getCategoryApi,
  getServicesUser
}
